import { Network } from '@xchainjs/xchain-client'

const safeEnv = (defaultEnv: string, env?: string) => {
  return env || defaultEnv
}

export type Config = {
  network: Network
}

export const config: Config = {
  network: safeEnv('testnet', process.env.REACT_APP_NETWORK) as Network,
}

export const IS_TESTNET =
  safeEnv('testnet', process.env.REACT_APP_NETWORK) === 'testnet'

export const IS_SYNTH_ACTIVE =
  safeEnv('testnet', process.env.REACT_APP_IS_SYNTH_ACTIVE) === 'true'
