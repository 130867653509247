import styled from 'styled-components'

import { CoreButton } from '../UIElements'

export const StyledButton = styled(CoreButton)`
  min-width: 30px !important;
  padding: 0;
  margin: auto;

  svg {
    width: 18px;
    height: 18px;
    > * {
      stroke: ${({ theme }) => theme.text1};
    }
  }
`
