export const MIDGARD_TESTNET_URL = process.env
  .REACT_APP_MIDGARD_TESTNET_URL as string
export const MIDGARD_CHAOSNET_URL = process.env
  .REACT_APP_MIDGARD_MAINNET_URL as string
export const MIDGARD_STAGENET_URL = process.env
  .REACT_APP_MIDGARD_STAGENET_URL as string

export const THORNODE_TESTNET_URL = process.env
  .REACT_APP_TESTNET_THORNODE_API as string
export const THORNODE_STAGENET_URL = process.env
  .REACT_APP_STAGENET_THORNODE_API as string
export const THORNODE_MAINNET_URL = process.env
  .REACT_APP_MAINNET_THORNODE_API as string
