import { lighten } from 'polished'

import lightTheme from './light'
import { palette, DARKENED_STRAW } from './palette'
import { Theme } from './types'

const { primary, dark, secondary } = palette

const darkTheme: Theme = JSON.parse(JSON.stringify(lightTheme))

darkTheme.palette = {
  ...darkTheme.palette,
  secondary: [
    DARKENED_STRAW, // 0 secondary
    '#0B2433', // 1 box-shadow, hover
  ],
  gray: [
    dark[8], // 0: Border
    DARKENED_STRAW, // 1: step bar, txstatus bg
    dark[5], // 2: hover
  ],
  background: [
    primary[0], // 0: header, footer bg
    primary[3], // 1: main bg (top half)
    secondary[3], // 2: hover
    dark[10], // 3: main bg (bottom half)
    dark[8], // 4: popover bg
    dark[9], // 5: panel
    lighten(0.05, '#0f2f43'), // 6: card (left)
    lighten(0.05, '#0B2433'), // 7: card (right)
    primary[0], // 8: swap button
  ],
  text: [
    '#ffffff', // 0: Normal Text (normal)
    dark[0], // 1: Active (dark)
    dark[1], // 2: light text
    '#ffffff', // 3: white text
    lighten(0.15, DARKENED_STRAW), // 4: gold text
    primary[4], // 5: dark gold text
  ],
}

export default darkTheme
