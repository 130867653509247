import styled from 'styled-components'
import { palette } from 'styled-theme'

export const PortfolioCardDiv = styled.div`
  position: relative;
  background-color: ${palette('background', 0)};
  padding: 2rem;
  margin: 10px;
  width: 100%;
  height: 100%;
  color: ${palette('background', 7)};
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #253054; //TODO: use palette
  box-shadow: rgb(0 0 0 / 5%) 0px 6px 10px;
`

export const Title = styled.p`
  color: ${palette('text', 0)};
  margin-bottom: 1px;
`

export const Value = styled.h2`
  font-weight: bold;
  color: ${palette('text', 0)}; ;
`

export const Content = styled.div`
  margin-left: 10px;
`
