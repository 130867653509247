import { Palette } from './types'

export const palette: Palette = {
  primary: [
    '#0D1942', // 50 darkblue
    '#000F35', // 100 darkblue
    '#0D1B94', // 200 darkblue
    '#1121B8', // 300 yale blue
    '#404DC6', // 400 dark gold
    '#3845C4', // 500
    '#2B4070', // 600 bluegray
    '#202C54', // 700 darkblue
    '#0D1B94', // 800 blue
    '#375BB9', // 900 honeydew
    '#1f2a50', // 1000
  ],
  secondary: [
    '#dff7ff', // 50
    '#ace9fe', // 100
    '#70dbfe', // 200
    '#d6cf65', // 300 secondary
    '#00c0ff', // 400
    '#00b4ff', // 500
    '#00a6f4', // 600
    '#0093e1', // 700
    '#0081cd', // 800
    '#0061ac', // 900
  ],
  gray: [
    '#f7f7f7', // 50,
    '#eeeeee', // 100,
    '#e2e2e2', // 200,
    '#DEE0EA', // 300, default text
    '#ababab', // 400,
    '#8a8a8a', // 500,
    '#636363', // 600,
    '#505050', // 700,
    '#323232', // 800,
    '#121212', // 900,
    '#253054', // border grey
  ],
  dark: [
    '#ababab', // 50 light gray
    '#969696', // 100 medium grey
    '#b4b9c2', // 200
    '#969dab', // 300 medium grey
    '#a9b3be', // 400
    '#0ECB81', // 500 turquoise grey
    '#40537E', // 600 dark deep jungle
    '#2B4070', // 700
    '#939393', // 800 light grey
    '#041018', // 900 night black
    '#141517', // Kim black
  ],
}

const { primary } = palette

export const DARKENED_STRAW = '#939393'
export const YGGDRASIL_GREEN = primary[3]
export const LITTLE_BOY_BLUE = primary[6]
export const FLASH_ORANGE = '#999999'
export const SURTR_RED = '#FF4954'
