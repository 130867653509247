export type PoolType = {
  type: string
  label: string
}

export const poolTypes = [
  {
    type: 'All',
    label: 'All',
  },
  {
    type: 'ETH',
    label: 'ETH',
  },
  {
    type: 'BNB',
    label: 'BNB',
  },
]
