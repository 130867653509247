import React, { useRef, useCallback } from 'react'

import { Amount } from 'multichain-sdk'
import { transparentize } from 'polished'
import styled from 'styled-components'
// import { palette } from 'styled-theme'

import {
  InputAmount,
  InputAmountProps,
} from 'components/UIElements/InputAmount'

import { AssetInputWrapper } from './AssetInput.style'

export type Props = {
  title: string
  info?: string
  amount: Amount
  label?: string
  inputProps?: InputAmountProps
  decimal?: number
  disabled?: boolean
  border?: boolean
  onChange: (value: Amount) => void
}

export const StyledInput = styled.p`
  width: 60%;
  height: 100%;
  background: none;
  color: ${({ theme }) => `${transparentize(0.5, theme.palette.text[0])}`};
  align-self: flex-start;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: clamp(5px, 3vw, 14px);
  place-content: center;
  border-radius: 0px 2px 2px 0px;
`

export const AssetInput: React.FC<Props> = (props): JSX.Element => {
  const {
    title,
    amount,
    info,
    label,
    inputProps = {},
    onChange,
    decimal = 8,
    border = true,
    ...otherProps
  } = props

  const inputRef = useRef<HTMLDivElement>(null)
  const { disabled = false } = inputProps

  const onChangeHandler = useCallback(
    (value: Amount) => {
      onChange(value)
    },
    [onChange],
  )

  const handleClickWrapper = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputRef.current?.firstChild?.focus()
  }, [])

  return (
    <AssetInputWrapper
      disabled={disabled}
      onClick={handleClickWrapper}
      border={border}
      {...otherProps}
    >
      {/* <div className="asset-input-header">
        <span className="asset-input-title">{title}</span>
        {info && <span className="asset-input-info">{info}</span>}
      </div> */}
      <div className="asset-input-content" ref={inputRef}>
        <InputAmount
          value={amount}
          onChange={onChangeHandler}
          sizevalue="big"
          decimal={decimal}
          outlined={false}
          {...inputProps}
        />
        <StyledInput>{info}</StyledInput>
        {label && <span className="asset-amount-label">{label}</span>}
      </div>
      {/* add slider here */}
    </AssetInputWrapper>
  )
}
