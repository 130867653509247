/* eslint-disable react/no-unescaped-entities */
import React, { useCallback } from 'react'

import { Button } from 'components/UIElements/Button'

import { PoolFilterWrapper } from './PoolTypeFilter.style'
import { poolTypes } from './poolTypes'

export type Props = {
  selected: string
  onClick: (key: string) => void
}

export const PoolTypeFilter: React.FC<Props> = (props: Props): JSX.Element => {
  const { selected, onClick } = props

  const handleClick = useCallback(
    (key: string) => {
      onClick(key)
    },
    [onClick],
  )

  return (
    <PoolFilterWrapper className="pool-type-filter">
      {poolTypes.map((data) => (
        <Button
          typevalue="outline"
          round
          onClick={() => handleClick(data.type)}
          focused={selected === data.type}
          fixedWidth={false}
          sizevalue="medium"
          key={data.type}
        >
          {data.label}
        </Button>
      ))}
    </PoolFilterWrapper>
  )
}
