import React from 'react'

import * as Styled from './PortfolioCard.style'

export type Props = {
  title?: string
  value?: string
  //   tooltip?: string
  //   status?: 'primary' | 'warning' | 'error'
}

export const PortfolioCard: React.FC<Props> = (props: Props): JSX.Element => {
  const { title, value } = props
  return (
    <Styled.PortfolioCardDiv>
      <Styled.Content>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Value>{value}</Styled.Value>
      </Styled.Content>
    </Styled.PortfolioCardDiv>
  )
}
