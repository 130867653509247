import {
  Chain,
  BNBChain,
  BTCChain,
  ETHChain,
  LTCChain,
  BCHChain,
  THORChain,
  DOGEChain,
  LUNAChain,
} from '@xchainjs/xchain-util'

export const getAssetType = (
  chain: Chain,
  ticker: string,
  isSynth = false,
): string => {
  let networkType: string

  if (chain === THORChain) {
    networkType = 'THOR'
  } else if (chain === BCHChain) {
    networkType = 'BCH'
  } else if (chain === LTCChain) {
    networkType = 'LTC'
  } else if (chain === BTCChain) {
    networkType = 'BTC'
  } else if (chain === BNBChain) {
    networkType = 'BNB'
  } else if (chain === DOGEChain) {
    networkType = 'DOGE'
  } else if (chain === ETHChain) {
    networkType = 'ETH'
  } else if (chain === LUNAChain) {
    networkType = 'LUNA'
  } else {
    networkType = chain
  }

  if (isSynth) {
    networkType = 'Synth'
  }

  return networkType
}

export const getNetworkName = (chain: Chain, ticker: string): string => {
  if (chain === BTCChain) return 'Bitcoin'
  if (chain === LTCChain) return 'Litecoin'
  if (chain === BCHChain) return 'Bitcoin Cash'
  if (chain === DOGEChain) return 'Dogecoin'
  if (chain === LUNAChain) return 'Terra'
  if (chain === ETHChain && ticker === 'ETH') {
    return 'Ethereum'
  }

  return ticker
}

export const getAssetName = (
  chain: Chain,
  ticker: string,
  isSynth = false,
): string => {
  return isSynth ? `Synth ${ticker}` : ticker
}
