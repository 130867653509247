import { Asset } from 'multichain-sdk'

import {
  getSwapRoute,
  PORTFOLIO_ROUTE,
  POOL_OVERVIEW_ROUTE,
} from 'settings/constants'

export const navMenuList = [
  {
    link: '/',
    label: 'Home',
    isWideOnly: false,
  },
  {
    link: getSwapRoute(Asset.BTC(), Asset.RUNE()),
    label: 'Swap',
    isWideOnly: false,
  },
  {
    link: POOL_OVERVIEW_ROUTE,
    label: 'Pool',
    isWideOnly: true,
  },
  {
    link: PORTFOLIO_ROUTE,
    label: 'Portfolio',
    isWideOnly: true,
  },
]
