import { TxHash, Balance, Network } from '@xchainjs/xchain-client'
import { Client as TerraClient } from '@xchainjs/xchain-terra'
import { baseAmount, Chain } from '@xchainjs/xchain-util'
import BigNumber from 'bignumber.js'

import { AmountType, Amount, Asset, AssetAmount } from '../entities'
import { IClient } from './client'
import { TxParams, WalletOption } from './types'

const MOCK_PHRASE =
  'image rally need wedding health address purse army antenna leopard sea gain'

export interface ITerraChain extends IClient {
  getClient(): TerraClient
}

export const getTERRAClient = (network: Network, phrase?: string) => {
  return new TerraClient({
    network,
    phrase,
  })
}

export class TerraChain implements ITerraChain {
  private balances: AssetAmount[] = []

  private client: TerraClient

  public readonly chain: Chain

  public walletType: WalletOption | null

  constructor({ network = Network.Testnet }: { network?: Network }) {
    this.chain = Chain.Terra
    this.client = getTERRAClient(network, MOCK_PHRASE)
    this.walletType = null
  }

  /**
   * get xchain-terra client
   */
  getClient(): TerraClient {
    return this.client
  }

  get balance() {
    return this.balances
  }

  connectKeystore = (phrase: string) => {
    const network = this.client.getNetwork()
    this.client = getTERRAClient(network, phrase)
    this.walletType = WalletOption.KEYSTORE
  }

  disconnect = () => {
    this.client.purgeClient()
    this.walletType = null
  }

  loadBalance = async (): Promise<AssetAmount[]> => {
    try {
      const address = this.client.getAddress()
      const terraBalance = await this.client.getBalance(address)
      let balances: Balance[] = await this.client.getBalance(
        this.client.getAddress(),
        undefined,
      )
      balances = balances.filter((balance: Balance) => balance.amount.gt(0))

      this.balances = await Promise.all(
        balances.map(async (data: Balance) => {
          const { asset, amount } = data

          const assetObj = new Asset(asset.chain, asset.symbol)

          // set asset decimal
          await assetObj.setDecimal(amount.decimal)

          const amountObj = assetObj.isLUNA()
            ? new Amount(
                new BigNumber(terraBalance.toString()),
                AmountType.BASE_AMOUNT,
                assetObj.decimal,
              )
            : new Amount(
                amount.amount(),
                AmountType.BASE_AMOUNT,
                assetObj.decimal,
              )
          return new AssetAmount(assetObj, amountObj)
        }),
      )

      return this.balances
    } catch (error) {
      return Promise.reject(error)
    }
  }

  hasAmountInBalance = async (assetAmount: AssetAmount): Promise<boolean> => {
    try {
      await this.loadBalance()

      const assetBalance = this.balances.find((data: AssetAmount) =>
        data.asset.eq(assetAmount.asset),
      )

      if (!assetBalance) return false

      return assetBalance.amount.gte(assetAmount.amount)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  getAssetBalance = async (asset: Asset): Promise<AssetAmount> => {
    try {
      await this.loadBalance()

      const assetBalance = this.balances.find((data: AssetAmount) =>
        data.asset.eq(asset),
      )
      if (!assetBalance)
        return new AssetAmount(asset, Amount.fromAssetAmount(0, asset.decimal))

      return assetBalance
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * transfer on binance chain
   * @param {TxParams} tx transfer parameter
   */
  transfer = async (tx: TxParams): Promise<TxHash> => {
    // use xchainjs-client standard internally
    try {
      const {
        assetAmount,
        recipient,
        memo,
        // feeOption = FeeOption.Fast,
      } = tx
      const { asset } = assetAmount
      const amount = baseAmount(assetAmount.amount.baseAmount, asset.decimal)

      return await this.client.transfer({
        asset: asset.getAssetObj(),
        amount,
        recipient,
        memo,
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
