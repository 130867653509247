/* eslint-disable react/no-unescaped-entities */
import React, { useCallback } from 'react'

import { CheckCircleOutlined, FieldTimeOutlined } from '@ant-design/icons'
import { PoolStatus } from 'midgard-sdk'

import { Button } from 'components/UIElements/Button'
import { Question } from 'components/UIElements/Tooltip'

import { PoolFilterWrapper } from './PoolStatusFilter.style'

export type Props = {
  selected: PoolStatus
  onClick: (key: PoolStatus) => void
}

export const PoolStatusFilter: React.FC<Props> = (
  props: Props,
): JSX.Element => {
  const { selected, onClick } = props

  const handleClick = useCallback(
    (key: PoolStatus) => {
      onClick(key)
    },
    [onClick],
  )

  return (
    <PoolFilterWrapper className="pool-status-filter">
      <Button
        typevalue="outline"
        round
        onClick={() => handleClick('available')}
        focused={selected === 'available'}
        fixedWidth={false}
        sizevalue="medium"
      >
        <CheckCircleOutlined />
        Active
      </Button>
      <Button
        typevalue="outline"
        round
        onClick={() => handleClick('staged')}
        focused={selected === 'staged'}
        fixedWidth={false}
        sizevalue="medium"
      >
        <FieldTimeOutlined />
        Pending
      </Button>
      <Question
        tooltip="Created Pools don't immediately become activated on THORChain and must participate
      in a liquidity competition to become activated. Every 43200 blocks (approx 2.5
      days), the pool with the most liquidity (at least 20k Rune depth: 10k Rune and 10k Rune equivalent in asset) wins & becomes activated. For staged Pools, Symmetric Deposit and Withdraw are available but Swap is not available."
      />
    </PoolFilterWrapper>
  )
}
