import React, { useCallback, useState, useMemo } from 'react'

import { Link } from 'react-router-dom'

import { Navbar } from 'components'
import { hasConnectedWallet } from 'multichain-sdk' // Removed Asset

// import { CurrencySelector } from 'components/CurrencySelector'

// import { useApp } from 'redux/app/hooks'
// import { useGlobalState } from 'redux/hooks'

import { useWallet } from 'redux/wallet/hooks'

import { HOME_ROUTE } from 'settings/constants'
// import { currencyIndexAssets } from 'settings/constants/currency'

import { Hamburger } from '../Hamburger'
import { Logo } from '../Logo'
// import { NetworkStatus } from '../NetworkStatus'
// {isGadgetVisible}&&<NetworkStatus/>
// import { Refresh } from '../Refresh'
// {isRefreshVisible}&&<Refresh onRefresh={refreshPage}/>
// import { TxManager } from '../TxManager'
import { WalletDrawer } from '../WalletDrawer'
import * as Styled from './Header.style'

export const Header = () => {
  // const { themeType, baseCurrencyAsset, setBaseCurrency } = useApp()
  // const { baseCurrencyAsset, setBaseCurrency } = useApp()
  // const { pathname } = useLocation()
  // const isNotHomeScreen = useMemo(() => pathname !== HOME_ROUTE, [pathname])
  const { wallet, isWalletLoading, setIsConnectModalOpen } = useWallet()
  // const { refreshPage } = useGlobalState()

  // const isRefreshVisible = useMedia('(min-width: 375px)')

  const [drawerVisible, setDrawerVisible] = useState(false)

  const isConnected = useMemo(() => hasConnectedWallet(wallet), [wallet])

  const handleClickWalletBtn = useCallback(() => {
    if (!isConnected && !isWalletLoading) {
      setIsConnectModalOpen(true)
    } else {
      setDrawerVisible(true)
    }
  }, [isConnected, isWalletLoading, setIsConnectModalOpen])

  const handleCloseDrawer = useCallback(() => {
    setDrawerVisible(false)
  }, [])

  // const handleSelectCurrency = useCallback(
  //   (baseAsset: Asset) => {
  //     setBaseCurrency(baseAsset)
  //   },
  //   [setBaseCurrency],
  // )

  return (
    <Styled.HeaderContainer>
      <Styled.HeaderLeft>
        <Styled.LogoWrapper>
          <Link to={HOME_ROUTE}>
            <Logo mini type="ccqswap" />
          </Link>
        </Styled.LogoWrapper>
      </Styled.HeaderLeft>
      <Navbar />
      <Styled.HeaderAction>
        <Styled.WalletBtn
          onClick={handleClickWalletBtn}
          connected={isConnected}
          loading={isWalletLoading}
        />
        <WalletDrawer visible={drawerVisible} onClose={handleCloseDrawer} />
        {/* isGadgetVisible && <TxManager /> */}
        <Hamburger />
      </Styled.HeaderAction>
    </Styled.HeaderContainer>
  )
}
