import React, { useCallback, useMemo } from 'react'

import { useHistory } from 'react-router'

import { getRuneToUpgrade } from 'multichain-sdk'

import { useApp } from 'redux/app/hooks'
import { useWallet } from 'redux/wallet/hooks'

import { useMedia } from 'hooks/useMedia'

import { SWAP_ROUTE, UPGRADE_RUNE_ROUTE } from 'settings/constants'

import { Header } from '../Header'
import WalletModal from '../WalletModal'
import * as Styled from './Layout.style'

export type Props = {
  transparent?: boolean
  children: React.ReactNode
}

export const Layout = (props: Props) => {
  const { children, transparent = false } = props
  const isBetaDisplayed = useMedia('(min-width: 690px)')
  const history = useHistory()
  const { wallet } = useWallet()
  const { showAnnouncement } = useApp()

  const oldRune: string | null = useMemo(() => {
    if (wallet) {
      const runesToUpgrade = getRuneToUpgrade(wallet)

      if (runesToUpgrade && runesToUpgrade.length > 0) {
        const oldRuneChain = `${runesToUpgrade?.[0]?.chain ?? ''} ${
          runesToUpgrade?.[1]?.chain ?? ''
        }`
        return `Click to upgrade ${oldRuneChain} RUNE to Native RUNE.`
      }
    }

    return null
  }, [wallet])
  const handleUpgrade = useCallback(() => {
    history.push(UPGRADE_RUNE_ROUTE)
  }, [history])

  const isTxPage = useMemo(() => {
    const { pathname } = window.location

    return pathname.includes(SWAP_ROUTE)
  }, [])

  return (
    <Styled.LayoutWrapper>
      {
        showAnnouncement &&
          isBetaDisplayed &&
          console.log('TerraYield is in Alpha')
        // <Alert
        //   message="TerraYield.com is has not yet launched, Take your own Risk. Always back up your wallet and do not play with large funds. Bookmark https://terrayield.com be safe. Close this message >>"
        //   type="warning"
        //   showIcon
        //   closable
        //   onClose={() => setReadStatus(true)}
        // />
      }
      <Header />
      <Styled.ContentWrapper transparent={transparent}>
        {isTxPage && oldRune && (
          <Styled.NotifyWrapper>
            <Styled.Notify onClick={handleUpgrade}>{oldRune}</Styled.Notify>
          </Styled.NotifyWrapper>
        )}
        {children}
      </Styled.ContentWrapper>
      <WalletModal />
    </Styled.LayoutWrapper>
  )
}
