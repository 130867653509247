import styled from 'styled-components'

export const PoolFilterWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0px;
    }
  }
`
